/**
 * Conditional ruffle loader
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import init, { Ruffle } from "../pkg/ruffle_web";
import { setPolyfillsOnLoad } from "./js-polyfills";
/**
 * Load ruffle from an automatically-detected location.
 *
 * This function returns a new instance of Ruffle and downloads it every time.
 * You should not use it directly; this module will memoize the resource
 * download.
 *
 * @returns A ruffle constructor that may be used to create new Ruffle
 * instances.
 */
function fetchRuffle() {
    return __awaiter(this, void 0, void 0, function* () {
        // Apply some pure JavaScript polyfills to prevent conflicts with external
        // libraries, if needed.
        setPolyfillsOnLoad();
        // wasm files are set to be resource assets,
        // so this import will resolve to the URL of the wasm file.
        const ruffleWasm = yield import(
        /* webpackMode: "eager" */
        "../pkg/ruffle_web_bg.wasm");
        yield init(ruffleWasm.default);
        return Ruffle;
    });
}
let lastLoaded = null;
/**
 * Obtain an instance of `Ruffle`.
 *
 * This function returns a promise which yields `Ruffle` asynchronously.
 *
 * @returns A ruffle constructor that may be used to create new Ruffle
 * instances.
 */
export function loadRuffle() {
    if (lastLoaded == null) {
        lastLoaded = fetchRuffle();
    }
    return lastLoaded;
}
